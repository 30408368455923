import { Flex, Heading, Skeleton, Text } from '@chakra-ui/react';
import { _js } from '@ifixit/localize';
import { initializeChakraComponent } from 'Shared/chakra-initialize';
import { Suspense } from 'react';

function HomePageNewsHeading() {
   return (
      <Suspense fallback={<Skeleton minHeight={{ base: '111.6px', sm: '84.85px' }} />}>
         <Flex
            direction="column"
            mx="6"
            my={{ base: 12, lg: 16 }}
            alignItems="center"
            textAlign="center"
         >
            <Heading m="0" fontSize="4xl">
               {_js('The Repair Pulse')}
            </Heading>
            <Text mt="6" mb={0} fontSize="lg">
               {_js('See inside the latest gadgets and follow the fight to repair our stuff.')}
            </Text>
         </Flex>
      </Suspense>
   );
}

initializeChakraComponent('HomePageNewsHeading', HomePageNewsHeading);
